import { notification } from 'antd';
import { Aboriginal, Disability, StudentPlanData } from '../Class/StudentPlan';
import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import ErrorMsg from '../Enums/ErrorMsg';
import { useAuthAxios } from './useAuthAxios';
import { useAuth0 } from '@auth0/auth0-react';

interface UpdateDisabilityPlanFunction {
    (studentPublicId: string, studentId: number, planUpdate: Disability, setStudentPlanData: (disabilityData?: StudentPlanData['disability'], aboriginalData?: StudentPlanData['aboriginal']) => void, customMessage?: string): Promise<void>;
}

interface UpdateAboriginalPlanFunction {
    (studentPublicId: string, studentId: number, planUpdate: Aboriginal, setStudentPlanData: (disabilityData?: StudentPlanData['disability'], aboriginalData?: StudentPlanData['aboriginal']) => void, customMessage?: string): Promise<void>;
}

interface setStudentPlanDataFunc{
    (disabilityData?: StudentPlanData['disability'], aboriginalData?: StudentPlanData['aboriginal']): void;
}

export const useUpdatePlanDetails = (): {updateDisabilityPlan: UpdateDisabilityPlanFunction, updateAboriginalPlan: UpdateAboriginalPlanFunction} => {
    const {axios, isAxiosError} = useAuthAxios();
    const { user } = useAuth0();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


    const errorNotification = useCallback((error: any, notification: any, customMessage?: string): void => {
        const errorMessage = isAxiosError(error) ? error.response?.data.message || 'An error occurred during the update.' : 'An unexpected error occurred.';
        notification.error({ message: 'Failed to update plan', description: errorMessage, placement: 'topRight' });
    },[isAxiosError]);

    const createPlan = useCallback(async (studentPublicId: string, studentId: number, planUpdate: Disability, setStudentPlanData: setStudentPlanDataFunc, customMessage?: string): Promise<void> => {
        const payload = {
            studentPublicId,
            studentId,
            planTypeId: process.env.REACT_APP_DISABLITY_PLANTYPE_ID,
            planData: JSON.stringify(planUpdate),
            createdBy: user?.name,
            modifiedBy: user?.name
        };

        try {
            const { data } = await axios.post('plans', payload);
            const disabilityData = JSON.parse(data.planData);
            setStudentPlanData(disabilityData);
            notification.success({ message: customMessage ? customMessage : 'New record added successfully!', placement: 'bottomRight' });
        } catch (error) {
            errorNotification(error, notification, customMessage);
        }
    },[axios, errorNotification, user?.name]);


    const handlePlanNotFound = useCallback((error: any, notification: any, updatePlanFunction: any, studentPublicId: string, studentId:number, planUpdate: any, setStudentPlanData: any, customMessage?: string): void => {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.data) {
            const errorObj = axiosError.response.data as { detail: string, message: string };
            if (errorObj.detail && errorObj.detail.includes(ErrorMsg.PlanNotFound)) {
                createPlan(studentPublicId, studentId, planUpdate, setStudentPlanData, customMessage);
                return;
            }
            if(errorObj.detail && errorObj.detail.includes(ErrorMsg.NotModified)){
                notification.error({ message: 'Please modify record before updating!', placement: 'topRight' });
                return;
            }
        }
        errorNotification(error, notification, customMessage);
    },[createPlan, errorNotification]);


    const updateAboriginalPlan = useCallback(async (studentPublicId: string, studentId: number, planUpdate: Aboriginal, setStudentPlanData: setStudentPlanDataFunc, customMessage?: string): Promise<void> => {
        if(isSubmitting){ return; }

        setIsSubmitting(true);
        
        const payload = {
            studentPublicId,
            planTypeId: process.env.REACT_APP_ABORIGINAL_PLANTYPE_ID,
            planData: JSON.stringify(planUpdate),
            createdBy: user?.name,
            modifiedBy: user?.name
        };

        try {
            const { data } = await axios.put('plans', payload);
            const updatedPlanData = JSON.parse(data.planData);

            setStudentPlanData(updatedPlanData);

            notification.success({ message: customMessage ? customMessage : 'New record added successfully!', placement: 'bottomRight' });
        } catch (error) {
            handlePlanNotFound(error, notification, updateAboriginalPlan, studentPublicId, studentId, planUpdate, setStudentPlanData, customMessage);
        } finally{
            setIsSubmitting(false);
        }
    }, [axios, handlePlanNotFound, isSubmitting, user?.name]);

    const updateDisabilityPlan = useCallback(async (studentPublicId:string, studentId: number, planUpdate: Disability, setStudentPlanData: setStudentPlanDataFunc,  customMessage?: string): Promise<void> => {
        if(isSubmitting){ return; }

        setIsSubmitting(true);

        const payload = {
            studentPublicId,
            planTypeId: process.env.REACT_APP_DISABLITY_PLANTYPE_ID,
            planData: JSON.stringify(planUpdate),
            createdBy: user?.name,
            modifiedBy: user?.name
        };

        try {
            const { data } = await axios.put('plans', payload);
            const disabilityData = JSON.parse(data.planData);
            setStudentPlanData(disabilityData);
            notification.success({ message: customMessage ? customMessage : 'New record added successfully!', placement: 'bottomRight' });
        } catch (error) {
            handlePlanNotFound(error, notification, updateDisabilityPlan, studentPublicId, studentId, planUpdate, setStudentPlanData, customMessage);
        } finally{
            setIsSubmitting(false);
        }
    }, [axios, handlePlanNotFound, isSubmitting, user?.name]);


    return {updateDisabilityPlan, updateAboriginalPlan};
};