import { v4 as uuidv4 } from 'uuid';

export class Identifiable {
    public id: string;
    public refId: string;
    public other: string;

    constructor(id: string = uuidv4(), refId: string = '', other: string = '') {
        this.id = id;
        this.refId = refId;
        this.other = other;
    }
}

export class StudentPlanData {
    public disability: Disability;
    public aboriginal: Aboriginal;

    constructor(disability: Disability = new Disability(), aboriginal: Aboriginal = new Aboriginal()) {
        this.disability = disability;
        this.aboriginal = aboriginal;
    }
}

export class Aboriginal {
    public meetingDate: string;

    constructor(meetingDate: string = '') {
        this.meetingDate = meetingDate;
    }
}

export class Disability {
    public meetingDetails: MeetingDetail[];
    public supportPersonnels: SupportPersonnel[];
    public transitions: Transition[];
    public studentStrengths: StudentStrength;
    public externalAssessmentSections: ExternalAssessmentSection;
    public internalAssessmentSections: InternalAssessmentSection;
    public functionalImpactSections: FunctionalImpactSection;
    public nccdDisabilities: NccdDisability[];
    public goals : Goal[];
    public professionalDevelopments: ProfessionalDevelopment[];
    public curriculumAssessmentSections: CurriculumAssessmentSection;
    public communicationSections: CommunicationSection;
    public environmentalSections: EnvironmentalSection;
    public movementSections: MovementSection;
    public personalCareSections: PersonalCareSection;
    public safetySections: SafetySection;
    public socialEmotionalSections: SocialEmotionalSection;
    public evidenceMonitoringSections: EvidenceMonitoringSection;

    constructor(
        meetingDetails: MeetingDetail[] = [],
        supportPersonnels: SupportPersonnel[] = [],
        transitions: Transition[] = [],
        studentStrengths: StudentStrength = new StudentStrength(),
        externalAssessmentSections: ExternalAssessmentSection = new ExternalAssessmentSection(),
        internalAssessmentSections: InternalAssessmentSection = new InternalAssessmentSection(),
        functionalImpactSections: FunctionalImpactSection = new FunctionalImpactSection(),
        nccdDisabilities: NccdDisability[] = [],
        goals: Goal[] = [],
        professionalDevelopments: ProfessionalDevelopment[] = [],
        curriculumAssessmentSections: CurriculumAssessmentSection = new CurriculumAssessmentSection(),
        communicationSections: CommunicationSection = new CommunicationSection(),
        environmentalSections: EnvironmentalSection = new EnvironmentalSection(),
        movementSections: MovementSection = new MovementSection(),
        personalCareSections: PersonalCareSection = new PersonalCareSection(),
        safetySections: SafetySection = new SafetySection(),
        socialEmotionalSections: SocialEmotionalSection = new SocialEmotionalSection(),
        evidenceMonitoringSections: EvidenceMonitoringSection = new EvidenceMonitoringSection()
    ) {
        this.meetingDetails = meetingDetails;
        this.supportPersonnels = supportPersonnels;
        this.transitions = transitions;
        this.studentStrengths = studentStrengths;
        this.externalAssessmentSections = externalAssessmentSections
        this.internalAssessmentSections = internalAssessmentSections;
        this.functionalImpactSections = functionalImpactSections;
        this.nccdDisabilities = nccdDisabilities;
        this.goals = goals;
        this.professionalDevelopments = professionalDevelopments;
        this.curriculumAssessmentSections = curriculumAssessmentSections;
        this.communicationSections = communicationSections;
        this.environmentalSections = environmentalSections;
        this.movementSections = movementSections;
        this.personalCareSections = personalCareSections;
        this.safetySections = safetySections;
        this.socialEmotionalSections = socialEmotionalSections;
        this.evidenceMonitoringSections = evidenceMonitoringSections;
    }
}



export class MeetingDetail extends Identifiable {
    public meetingDate: string;
    public purposes: Purpose[];
    public summary: string;
    public roles: Identifiable[];
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        meetingDate: string = '',
        purposes: Purpose[] = [],
        summary: string = '',
        roles: Identifiable[] = [],
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.meetingDate = meetingDate;
        this.purposes = purposes;
        this.summary = summary;
        this.roles = roles;
        this.isArchived = isArchived;
    }
}


export class SupportPersonnel extends Identifiable {
    public name: string;
    public year: string;
    public timeFrame: string;
    public intensities: Intensity[];
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        name: string = '',
        year: string = '',
        timeFrame: string = '',
        intensities: Intensity[] = [],
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.name = name;
        this.year = year;
        this.timeFrame = timeFrame;
        this.intensities = intensities;
        this.isArchived = isArchived;
    }
}

export class Transition extends Identifiable{
    public transitionDate: string;
    public keyPersonnels: Identifiable[];
    public parentCarerStudentInput: string;
    public teacherAnecdotalInfoInput: string;
    public listOfDocumentsToTransfered: string;
    public consentToShareInformation: string;
    public material: string;
    public transportTravelTrainingArrangements: string;
    public workPlacement: string;
    public infoRegardingPossibleServiceProviders: string;
    public applyForADHC: boolean;
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        transitionDate: string = '',
        keyPersonnels: Identifiable[] = [],
        parentCarerStudentInput: string = '',
        teacherAnecdotalInfoInput: string = '',
        listOfDocumentsToTransfered: string = '',
        consentToShareInformation: string = '',
        material: string = '',
        transportTravelTrainingArrangements: string = '',
        workPlacement: string = '',
        infoRegardingPossibleServiceProviders: string = '',
        applyForADHC: boolean = false,
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.transitionDate = transitionDate;
        this.keyPersonnels = keyPersonnels;
        this.parentCarerStudentInput = parentCarerStudentInput;
        this.teacherAnecdotalInfoInput = teacherAnecdotalInfoInput;
        this.listOfDocumentsToTransfered = listOfDocumentsToTransfered;
        this.consentToShareInformation = consentToShareInformation;
        this.material = material;
        this.transportTravelTrainingArrangements = transportTravelTrainingArrangements;
        this.workPlacement = workPlacement;
        this.infoRegardingPossibleServiceProviders = infoRegardingPossibleServiceProviders;
        this.applyForADHC = applyForADHC;
        this.isArchived = isArchived;
    }
}

export class ExternalAssessmentSection {
    public externalAssessments: Assessment[];
    public diagnosticInformation: string;

    constructor(
        externalAssessments: Assessment[] = [],
        diagnosticInformation: string = ''
    ) {
        this.externalAssessments = externalAssessments;
        this.diagnosticInformation = diagnosticInformation;
    }
}

export class InternalAssessmentSection {
    public internalAssessments: Assessment[];
    public imputedDisabilityInformation: string;

    constructor(
        internalAssessments: Assessment[] = [],
        imputedDisabilityInformation: string = ''
    ) {
        this.internalAssessments = internalAssessments;
        this.imputedDisabilityInformation = imputedDisabilityInformation;
    }
}

export class Assessment extends Identifiable{
    public assessmentDate: string;
    public isCurrent: boolean;
    public details: string;
    public fileLocation: string;
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        assessmentDate: string = '',
        isCurrent: boolean = false,
        details: string = '',
        fileLocation: string = '',
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.assessmentDate = assessmentDate;
        this.isCurrent = isCurrent;
        this.details = details;
        this.fileLocation = fileLocation;
        this.isArchived = isArchived;
    }
}

export class FunctionalImpactSection {
    public functionalImpacts: Identifiable[];
    public currentAdditionalPlans: Identifiable[];
    public schoolAttendanceInformation: string;

    constructor(functionalImpacts: Identifiable[] = [], currentAdditionalPlans: Identifiable[] = [], schoolAttendanceInformation: string = '') {
        this.functionalImpacts = functionalImpacts;
        this.currentAdditionalPlans = currentAdditionalPlans;
        this.schoolAttendanceInformation = schoolAttendanceInformation;
    }
}


export class Intensity {
    public id: string;
    public refId: string;
    public other: string;
    public frequency: Identifiable;

    constructor(id: string = uuidv4(), refId: string = '', other: string = '', frequency: Identifiable = new Identifiable()) {
        this.id = id;
        this.refId = refId;
        this.other = other;
        this.frequency = frequency;
    }
}


export class Purpose {
    public id: string;
    public refId: string;
    public other?: string;

    constructor(id: string = uuidv4(), refId: string = '', other?: string) {
        this.id = id;
        this.refId = refId;
        this.other = other;
    }
}

export class StudentStrength {
    public studentInterests: Identifiable[];
    public areaOfPersonalStrength: string;

    constructor(studentInterests: Identifiable[] = [], areaOfPersonalStrength: string = '') {
        this.studentInterests = studentInterests;
        this.areaOfPersonalStrength = areaOfPersonalStrength;
    }
}


export class NccdDisability extends Identifiable {
    public priority: string;
    public category: Category;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        priority: string = '',
        category: Category = new Category()
    ) {
        super(id, refId, other);
        this.priority = priority;
        this.category = category;
    }
}

export class Category extends Identifiable {
    public specific: Identifiable;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        specific: Identifiable = new Identifiable()
    ) {
        super(id, refId, other);
        this.specific = specific;
    }
}

export class Goal extends Identifiable {
    public studentLearningGoal: string;
    public implementationDate: string;
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        studentLearningGoal: string = '',
        implementationDate: string = '',
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.studentLearningGoal = studentLearningGoal;
        this.implementationDate = implementationDate;
        this.isArchived = isArchived;
    }
}

export class ProfessionalDevelopment extends Identifiable {
    public typeOfTraining: string;
    public serviceProvider: string;
    public attendees: Identifiable[];
    public frequency: string;
    public meetingDate: string;
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        typeOfTraining: string = '',
        serviceProvider: string = '',
        attendees: Identifiable[] = [],
        frequency: string = '',
        meetingDate: string = '',
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.typeOfTraining = typeOfTraining;
        this.serviceProvider = serviceProvider;
        this.attendees = attendees;
        this.frequency = frequency;
        this.meetingDate = meetingDate;
        this.isArchived = isArchived;
    }
}

export class CurriculumAssessmentSection {
    public syllabusOutcomes: Identifiable[];
    public syllabusFurtherDetails: string;
    public interventions: Identifiable[];
    public alternateMaterials: Identifiable[];
    public tasks: Identifiable[];
    public explicitTeachings: Identifiable[];
    public visualSupports: Identifiable[];
    public assistiveTechnologies: Identifiable[];
    public brailleLowVisions: Identifiable[];
    public assessmentAdjustmentsSections: AssessmentAdjustmentsSection;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        syllabusOutcomes: Identifiable[] = new Array<Identifiable>(), 
        syllabusFurtherDetails: string = '', 
        interventions: Identifiable[] = [], 
        alternateMaterials: Identifiable[] = [],
        tasks: Identifiable[] = [],
        explicitTeachings: Identifiable[] = [],
        visualSupports: Identifiable[] = [],
        assistiveTechnologies: Identifiable[] = [],
        brailleLowVisions: Identifiable[] = [],
        assessmentAdjustments: AssessmentAdjustmentsSection = new AssessmentAdjustmentsSection(),
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.syllabusOutcomes = syllabusOutcomes;
        this.syllabusFurtherDetails = syllabusFurtherDetails;
        this.interventions = interventions;
        this.alternateMaterials = alternateMaterials;
        this.tasks = tasks;
        this.explicitTeachings = explicitTeachings;
        this.visualSupports = visualSupports;
        this.assistiveTechnologies = assistiveTechnologies;
        this.brailleLowVisions = brailleLowVisions;
        this.assessmentAdjustmentsSections = assessmentAdjustments;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class AssessmentAdjustmentsSection {
    public assessmentAdjustments: Identifiable[];
    public specificAdjustmentsIntervention: string;
    public specificAdjustmentsDisability: string;
    public specialistSoftware: string;

    constructor(
        assessmentAdjustments: Identifiable[] = [],
        specificAdjustmentsIntervention: string = '',
        specificAdjustmentsDisability: string = '',
        specialistSoftware: string = ''

    ) {
        this.assessmentAdjustments = assessmentAdjustments;
        this.specificAdjustmentsIntervention = specificAdjustmentsIntervention;
        this.specificAdjustmentsDisability = specificAdjustmentsDisability;
        this.specialistSoftware = specialistSoftware;
    }
}

export class CommunicationSection {
    public communications: Identifiable[];
    public furtherInformationCommunication: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        communications: Identifiable[] = [],
        furtherInformationCommunication: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.communications = communications;
        this.furtherInformationCommunication = furtherInformationCommunication;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class EnvironmentalSection {
    public environmentals: Identifiable[];
    public furtherInformationEnvironmental: string;
    public furtherInformationSpecialistEquipment: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        environmentals: Identifiable[] = [],
        furtherInformationEnvironmental: string = '',
        furtherInformationSpecialistEquipment: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.environmentals = environmentals;
        this.furtherInformationEnvironmental = furtherInformationEnvironmental;
        this.furtherInformationSpecialistEquipment = furtherInformationSpecialistEquipment;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class MovementSection {
    public movements: Identifiable[];
    public movementPlannings: Identifiable[];
    public furtherInformationMovement: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        movements: Identifiable[] = [],
        movementPlannings: Identifiable[] = [],
        furtherInformationMovement: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.movements = movements;
        this.movementPlannings = movementPlannings;
        this.furtherInformationMovement = furtherInformationMovement;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class PersonalCareSection {
    public personalCares: Identifiable[];
    public personalCarePlannings: Identifiable[];
    public furtherInformationPersonalCare: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        personalCares: Identifiable[] = [],
        personalCarePlannings: Identifiable[] = [],
        furtherInformationPersonalCare: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.personalCares = personalCares;
        this.personalCarePlannings = personalCarePlannings;
        this.furtherInformationPersonalCare = furtherInformationPersonalCare;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class SafetySection {
    public safeties: Identifiable[];
    public safetyPlannings : Identifiable[];
    public furtherInformationSafety: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        safeties: Identifiable[] = [],
        safetyPlannings: Identifiable[] = [],
        furtherInformationSafety: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.safeties = safeties;
        this.safetyPlannings = safetyPlannings;
        this.furtherInformationSafety = furtherInformationSafety;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class SocialEmotionalSection {
    public socialEmotionals: Identifiable[];
    public socialEmotionalPlannings: Identifiable[];
    public furtherInformationSocialEmotional: string;
    public deliveryAdjustments: DeliveryAdjustment[];

    constructor(
        socialEmotionals: Identifiable[] = [],
        socialEmotionalPlannings: Identifiable[] = [],
        furtherInformationSocialEmotional: string = '',
        deliveryAdjustments: DeliveryAdjustment[] = []
    ) {
        this.socialEmotionals = socialEmotionals;
        this.socialEmotionalPlannings = socialEmotionalPlannings;
        this.furtherInformationSocialEmotional = furtherInformationSocialEmotional;
        this.deliveryAdjustments = deliveryAdjustments;
    }
}

export class DeliveryAdjustment extends Identifiable {
    public learningEnvironmentSettings: Identifiable[] = [];
    public frequency: Identifiable;
    public timeFrame: string;
    public isArchived: boolean;

    constructor(
        id: string = uuidv4(),
        refId: string = '',
        other: string = '',
        learningEnvironmentSettings: Identifiable[] = [],
        frequency: Identifiable = new Identifiable(),
        timeFrame: string = '',
        isArchived: boolean = false
    ) {
        super(id, refId, other);
        this.learningEnvironmentSettings = learningEnvironmentSettings;
        this.frequency = frequency;
        this.timeFrame = timeFrame;
        this.isArchived = isArchived;
    }
}

export class EvidenceMonitoringSection {
    public evidenceMonitorings: Identifiable[];
    public furtherDetailsEvidenceMonitoring: string;

    constructor(
        evidenceMonitorings: Identifiable[] = [],
        furtherDetailsEvidenceMonitoring: string = ''
    ) {
        this.evidenceMonitorings = evidenceMonitorings;
        this.furtherDetailsEvidenceMonitoring = furtherDetailsEvidenceMonitoring;
    }
}