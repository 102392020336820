import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { NccdDisability } from "../Class/StudentPlan";
import { NccdDisabilityLegacy } from "../Class/NccdDisabilityLegacy";
import { useEffect } from "react";

export const useFetchNccdDisability = (studentPublicId: string, setNccdDisabilityDataLegacy: (data: NccdDisabilityLegacy[])=>void)=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchNccdDisability = async () => {
        if(error) { return []; }

        const cachedData = getFromLocalStorage(`NCCDDISABILITY_${studentPublicId.toLowerCase()}`);
        if (cachedData) { return cachedData; }
    
        try {
            const endpoint = `nccd/ppStudentDisability/${studentPublicId}`;
            const { data } = await axios.get<NccdDisability[]>(endpoint);
            saveToLocalStorage(`NCCDDISABILITY_${studentPublicId.toLowerCase()}`, data, Number(process.env.REACT_APP_DATA_EXP_MINUTES));
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            return [];
        }
    };
    
    const nccdDisabilityQuery = useQuery<NccdDisabilityLegacy[]>('nccdDisability', fetchNccdDisability, {
        staleTime: 0,
        retry: 2,
    });

    useEffect(() => {
        if(!nccdDisabilityQuery.isLoading){
            if(nccdDisabilityQuery.data){
                setNccdDisabilityDataLegacy(nccdDisabilityQuery.data);
            }
        }
    }, [nccdDisabilityQuery.data, nccdDisabilityQuery.isLoading, setNccdDisabilityDataLegacy]);

    return {isLoading: nccdDisabilityQuery.isLoading};
}