import React from "react";
import { Loading } from "../Components/Layout/Loading";
import { useFetchNaplan } from "../Hooks/useFetchNaplan";
import { NaplanContext } from "../Context/NaplanContext";

interface NaplanProviderProps {
    studentPublicId: string;
    children: React.ReactNode;
}

export  const NaplanProvider: React.FC<NaplanProviderProps> = ({studentPublicId, children }) => {
    const { data, isLoading } = useFetchNaplan(studentPublicId);
    

    if (isLoading) return <Loading />;

    return (
        <NaplanContext.Provider value={{ naplanData: data}}>
            {children}
        </NaplanContext.Provider>
    );
};