import React, { useEffect, useState } from 'react';
import {  Disability } from "../Class/StudentPlan"
import { useStudentPlan } from '../Context/StudentPlanContext';
import { DisabilitySchema, handleYupError } from '../Validations/DisabilitySchema';
import * as Yup from 'yup';
import { FormSubmitContext } from '../Context/FormSubmitContext';
import { useUpdatePlanDetails } from '../Hooks/useUpdatePlanDetails';

interface FormSubmitProviderProps {
    children: React.ReactNode;
}

export const FormSubmitProvider: React.FC<FormSubmitProviderProps> = ({children}) => {
    const { studentPlanData, student, studentPublicId, setStudentPlanData } = useStudentPlan();
    const {updateDisabilityPlan} = useUpdatePlanDetails();
    const [initialFormData, setInitialFormData] = useState<Disability>(studentPlanData.disability);
    const [formData, setFormData] = useState<Disability>(studentPlanData.disability);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string | string[] }>({});
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!hasInitialized) {
            setInitialFormData(studentPlanData.disability);
            setFormData(studentPlanData.disability);
        }
    }, [studentPlanData.disability, hasInitialized] );

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            await DisabilitySchema.validate(formData, {abortEarly: false});
            setErrors({});
            const planUpdate: Disability = {
                ...studentPlanData.disability, 
                studentStrengths: formData.studentStrengths, 
                functionalImpactSections: formData.functionalImpactSections,
                curriculumAssessmentSections: { ...formData.curriculumAssessmentSections, deliveryAdjustments: studentPlanData.disability.curriculumAssessmentSections.deliveryAdjustments},
                communicationSections: {...formData.communicationSections, deliveryAdjustments: studentPlanData.disability.communicationSections.deliveryAdjustments},
                environmentalSections: {...formData.environmentalSections, deliveryAdjustments: studentPlanData.disability.environmentalSections.deliveryAdjustments},
                movementSections: {...formData.movementSections, deliveryAdjustments: studentPlanData.disability.movementSections.deliveryAdjustments},
                personalCareSections: {...formData.personalCareSections, deliveryAdjustments: studentPlanData.disability.personalCareSections.deliveryAdjustments},
                safetySections: {...formData.safetySections, deliveryAdjustments: studentPlanData.disability.safetySections.deliveryAdjustments},
                socialEmotionalSections: {...formData.socialEmotionalSections, deliveryAdjustments: studentPlanData.disability.socialEmotionalSections.deliveryAdjustments},
                evidenceMonitoringSections: formData.evidenceMonitoringSections,
            };

            await updateDisabilityPlan(studentPublicId, student.studentId, planUpdate, setStudentPlanData, "Plan updated successfully!");
            setInitialFormData(planUpdate);
            setFormData(planUpdate);
        } catch (err) {
            if (err instanceof Yup.ValidationError) { 
                setErrors(handleYupError(err)); 
            }
        } finally{ 
            setIsSubmitting(false); 
        }
    };

    return (
        <FormSubmitContext.Provider value={{formData, setFormData, initialFormData, handleSubmit, isSubmitting, setIsSubmitting, errors, setErrors, setHasInitialized }}>
            {children}
        </FormSubmitContext.Provider>
    );
};
