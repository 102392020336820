import { Naplan } from "../Class/Naplan";

function isNaplan(obj: any): obj is Naplan {
    return (
        obj &&
        typeof obj.yearId === 'number' &&
        typeof obj.subject === 'string' &&
        typeof obj.bandId === 'number'
    );
}

function isNaplans(items: any[]): items is Naplan[] {
    if(!Array.isArray(items)) return false;
    return items.every(isNaplan);
}

export default isNaplans;