import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import LookupCheckboxItem from "../Types/LookupCheckboxItem";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";

export const useFetchLookupCheckbox = ()=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchLookupCheckbox = async () => {
        if(error) { return []; }

        const cachedData = getFromLocalStorage('LOOKUP_CHECKBOX');
        if (cachedData) { return cachedData; }
    
        try {
            const { data } = await axios.get<LookupCheckboxItem[]>('lookup_checkboxes');
            saveToLocalStorage('LOOKUP_CHECKBOX', data, Number(process.env.REACT_APP_LOOKUP_LIST_EXP_MINUTES));
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            return [];
        }
    };

    return useQuery('lookupCheckbox', fetchLookupCheckbox, {
        staleTime: 0,
        retry: 2,
    });
}