import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  {Button, Modal}  from "antd";
import { faArrowRotateRight, faCircleUser, faQuestionCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as Logo} from "../../Assets/Images/Logodark.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormSubmitContext } from "../../Context/FormSubmitContext";
import { useStudentPlan } from "../../Context/StudentPlanContext";
import { ReportMenu } from "./Reports/ReportMenu";
import { Dropdown, MenuItem } from "../Controls/Dropdown";
import {ReactComponent as FaSpinnerThird} from "../../Assets/Images/FaSpinnerThird.svg";


export const NavBar = ()=> {
    const { logout, user } = useAuth0();
    const navigate = useNavigate();
    const {student} = useStudentPlan();
    const location = useLocation();
    const {formData, initialFormData, handleSubmit, isSubmitting} = useFormSubmitContext();
    const [isFormDataChanged, setIsFormDataChanged] = React.useState<boolean>(false);

    const logoutWithRedirect = () =>
      logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
      });

    const onHelpClick = () => {
      window.open("https://csnsw.link/disability-support-kb", "_blank");
    };

    const onRefreshClick = async () => {
      Modal.confirm({
        title: 'Are you sure you want to clear cache?',
        content: 'Any unsaved changes will be lost and the latest plan details retrieved from the server.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() { localStorage.clear();
          window.location.reload();},
        onCancel() {  },
      }); 
    };

    const handleLogoClick = () => {
        const currentPath = location.pathname;
        const newPath = currentPath.split('/').slice(0, -1).join('/');
        navigate(newPath, { replace: true });
    };

    useEffect(() => {
      setIsFormDataChanged(JSON.stringify(formData) !== JSON.stringify(initialFormData));
    }, [formData, initialFormData]);

    const menuItems : MenuItem[] = [
      {key: "username", label: <div className="flex flex-col cursor-default">Hi, {user?.name}!</div> },
      {key: "signout", label: <button className="nav-link text-blue-500" aria-current="page" onClick={logoutWithRedirect}>Sign Out</button> },
      {key: "clear-cache", label: <div onClick={onRefreshClick}>Clear Cache <FontAwesomeIcon icon={faArrowRotateRight} className="mx-1" /></div> }
    ];
  

    return (
        <div className="px-2 w-full flex justify-center bg-white border-b-2 border-blue-400 sticky top-0 z-50">
            <div className="flex w-full items-center max-w-screen-4xl">
                  <div onClick={handleLogoClick} className="nav-link mx-4 cursor-pointer" aria-current="page" >
                    <Logo/>
                  </div>
                  <div className="flex flex-col items-center w-full mr-4">

                      <div className="ml-auto text-sm flex mt-2 items-center">
                          <Dropdown trigger={<FontAwesomeIcon icon={faCircleUser} className="text-3xl rounded-2xl border-2 shadow-circle hover:shadow-blue-400 border-blue-500" />} 
                            menuItems={menuItems} />
                      </div>
                      
                      <div className="flex items-center border-b-2">
                          <span className="text-xl lg:text-2xl font-bold">{student.studentFirstName} {student.studentLastName}</span>
                          <span className="font-bold lg:mx-4">{student.orgnisationName && `| ${student.orgnisationName} | ${student.gradeDescription} |`}</span>
                          <span className="font-bold mx-1">{student.locationSuburb}</span>
                      </div>

                      <div className="my-1 ml-auto flex">
                          <Button onClick={handleSubmit} disabled={isSubmitting || !isFormDataChanged} className="mx-2 flex disabled:bg-green-950 bg-green-500 hover:bg-green-600 text-white py-1 rounded">
                            Save{isSubmitting ? <FaSpinnerThird className="mx-2 w-4 animate-spin mt-1 fill-white "/> : <FontAwesomeIcon icon={faSave} className="mx-2 mt-1"/>}
                          </Button>
                          <ReportMenu />
                          <Button onClick={onHelpClick} className="bg-maroon-500 hover:bg-maroon-600 text-white py-1 px-2 lg:px-4 rounded mx-2">
                            Help<FontAwesomeIcon icon={faQuestionCircle} className="mx-1"/>
                          </Button>
                      </div>
                  </div>
            </div>
        </div>
    );
}

