import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { Naplan } from "../Class/Naplan";

export const useFetchNaplan = (studentPublicId: string)=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchNaplan = async () => {
        if(error) { return []; }

        const cachedData = getFromLocalStorage(`NAPLAN_${studentPublicId.toLowerCase()}`);
        if (cachedData) { return cachedData; }
    
        try {
            const endpoint = `plans/StudentNaplan/${studentPublicId}`;
            const { data } = await axios.get<Naplan[]>(endpoint);
            saveToLocalStorage(`NAPLAN_${studentPublicId.toLowerCase()}`, data, Number(process.env.REACT_APP_DATA_EXP_MINUTES));
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            return [];
        }
    };
    
    return useQuery('naplan', fetchNaplan, {
        staleTime: 0,
        retry: 2,
    });
}