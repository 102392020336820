import { useContext, useState } from "react";
import { ErrorHandlingContext } from "../Context/ErrorHandlingContext";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../Enums/ErrorMsg";

export const ErrorHandlingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [error, setErrorState] = useState(null);
    const navigate = useNavigate();

    const setError = (error: any) => {
        if(error){
            console.log(error);
            if(error.message === ErrorMsg.Unauthorised){
                navigate('/unauthorized');
            }
            if(error.message === ErrorMsg.NetworkError || (error.response && error.response.status === 500)){
                navigate('/internal-server-error');
            }
            if(error.response && error.response.data && error.response.data.exceptionDetails && error.response.data.exceptionDetails[0].message === ErrorMsg.StudentNotFound){
                navigate('/student-not-found');
            }
            if(error.message === ErrorMsg.InvalidPublicId){
                navigate('/404');
            }
        }
        setErrorState(error);
    };

    return (
        <ErrorHandlingContext.Provider value={{ error, setError }}>
            {children}
        </ErrorHandlingContext.Provider>
    );
};

export const useErrorHandling = () => {
    const context = useContext(ErrorHandlingContext);
    if (!context) {
        throw new Error('useErrorHandling must be used within an ErrorHandlingProvider');
    }
    return context;
};