import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile, faCaretDown, faDownload, faListCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useState} from "react";
import { Button, Grid} from 'antd';
import { Dropdown, MenuItem } from "../../Controls/Dropdown";
import { ReportSummaryForm } from "./ReportSummaryForm";
import { ReportSummaryCheckbox, getAllReportSummaryCheckboxes } from "./ReportSummaryCheckbox";
import { ReportSummaryFormType, useReportSummaryForm } from "./useReportSummaryForm";
import { useStudentPlan } from "../../../Context/StudentPlanContext";
import {ReactComponent as FaSpinnerThird} from "../../../Assets/Images/FaSpinnerThird.svg";
import { FullReportForm } from "./FullReportForm.tsx";
import { GoalReportForm } from "./GoalReportForm";


export const ReportMenu: React.FC<any> = ()=> {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const [toggle, setToggle] = useState(false);
    const [isFullModalVisible, setIsFullModalVisible] = useState<boolean>(false);
    const [isGoalModalVisible, setIsGoalModalVisible] = useState<boolean>(false);
    const [isSummaryModalVisible, setIsSummaryModalVisible] = useState<boolean>(false);
    const summaryTitle = "Summary Report Options";
    const fullReportTitle = "Full Report";
    const goalReportTitle = "Goal and Adjustments Report";
    const {studentPublicId} = useStudentPlan();
    
    const [initialData] = useState<ReportSummaryFormType[]>(getAllReportSummaryCheckboxes().map((item: ReportSummaryCheckbox) => ({ key: item.key, value: false })));
    const {formData, setFormData, isSubmitting, handleCheckboxChange, handleFullReport, handleGoalsReport, handleSummaryReport, asOfDate, handleDateChange  } = useReportSummaryForm({ initialData, studentPublicId, setIsSummaryModalVisible });


    const menuItems : MenuItem[] = [
        { key: '1', label: ( <div onClick={()=>setIsFullModalVisible(true)} className="w-full">Full Report<FontAwesomeIcon icon={faDownload} className="mx-2"/></div> ) },
        { key: '2', label: ( <div onClick={()=>setIsGoalModalVisible(true)} className="w-full">Goals and Adjustments Report<FontAwesomeIcon icon={faDownload} className="mx-2"/></div>)  },
        { key: '3', label: ( <div onClick={()=>setIsSummaryModalVisible(true)} className="w-full">Summary Report<FontAwesomeIcon icon={faListCheck} className="mx-2"/></div> ) },
    ];


    return (
        <>
            <Dropdown menuItems={menuItems} 
            trigger={
            <Button
                size={screens.xs ? "small" : "middle"} className="bg-green-500 hover:bg-green-600 text-white"
                icon={<FontAwesomeIcon icon={faFile} className="text-sm sm:text-lg text-white" />}>
                Reports 
                <FontAwesomeIcon icon={faCaretDown} className={`text-sm sm:text-lg text-white mx-1 cursor-pointer transition-transform ${toggle ? 'rotate-180' : 'rotate-0'} duration-500`} />
            </Button>}
            className="text-white px-4 rounded" setToggle={setToggle}/>
            <FullReportForm isFullModalVisible={isFullModalVisible} setIsFullModalVisible={setIsFullModalVisible} formData={formData} setFormData={setFormData} handleCheckboxChange={handleCheckboxChange} isSubmitting={isSubmitting} initialData={initialData} title={fullReportTitle} handleFullReport={handleFullReport} asOfDate={asOfDate} handleDateChange={handleDateChange}/>
            <GoalReportForm isGoalModalVisible={isGoalModalVisible} setIsGoalModalVisible={setIsGoalModalVisible} formData={formData} setFormData={setFormData} handleCheckboxChange={handleCheckboxChange} isSubmitting={isSubmitting} initialData={initialData} title={goalReportTitle} handleGoalsReport={handleGoalsReport} asOfDate={asOfDate} handleDateChange={handleDateChange}/>
            <ReportSummaryForm isModalVisible={isSummaryModalVisible} setIsModalVisible={setIsSummaryModalVisible} formData={formData} setFormData={setFormData} handleCheckboxChange={handleCheckboxChange} isSubmitting={isSubmitting} initialData={initialData} title={summaryTitle} handleSummaryReport={handleSummaryReport} asOfDate={asOfDate} handleDateChange={handleDateChange}/>
        </>
    );
}