import { Student } from "../Class/Student";

function isStudent(obj: any): obj is Student {
    return (
        obj &&
        typeof obj.studentFirstName === 'string' &&
        typeof obj.studentLastName === 'string' &&
        typeof obj.orgnisationName === 'string' &&
        typeof obj.locationSuburb === 'string' &&
        typeof obj.gradeId === 'number' &&
        typeof obj.gradeDescription === 'string' &&
        typeof obj.studentId === 'number'
    );
}

export default isStudent;
