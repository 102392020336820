import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import LookupListItem from "../Types/LookupListItem";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";

export const useFetchLookupList = ()=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchLookupList = async () => {
        if(error) { return []; }

        const cachedData = getFromLocalStorage('LOOKUP_LIST');
        if (cachedData) { return Array.isArray(cachedData) ? cachedData : []; }
    
        try {
            const { data } = await axios.get<LookupListItem[]>('lookup_list');
            saveToLocalStorage('LOOKUP_LIST', data, Number(process.env.REACT_APP_LOOKUP_LIST_EXP_MINUTES));
            setError(null);
            return Array.isArray(data) ? data : [];
        } catch (error) {
            setError(error);
            return [];
        }
    };
    
    return useQuery('lookupList', fetchLookupList, {
        staleTime: 0,
        retry: 2,
    });
}