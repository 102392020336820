import { Modal } from "antd";
import { ReactNode } from "react";

interface IReusableModalProps {
    isVisible: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
    className?: string;
  }

interface ModalConfirmCloseProps {
    onCancel?: () => void;
}

export const FormModal = ({isVisible, onClose, title, children, className}: IReusableModalProps) => {
    return (
      <Modal className={`${className ? className : 'sm:w-[75%] md:max-w-screen-xl'}`} open={isVisible} onCancel={onClose} footer={[]} maskClosable={false}>
          <h1 className="text-md sm:text-2xl mb-2 sm:mb-4">{title}</h1>
          <div className="border-1" />
          {children}
      </Modal>
    );
  };

  export const handleCancel = ({ onCancel }: ModalConfirmCloseProps) => {
      Modal.confirm({
      title: 'Are you sure you want to cancel?',
      content: 'Any changes you have made will be lost.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { onCancel?.();},
      onCancel() {  },
  }); 
};
