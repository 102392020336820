import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './Container/App';


interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

interface IAppState {
  returnTo?: string;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: IAppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID|| '',
    onRedirectCallback,
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH_AUDIENCE|| '',
      redirect_uri: window.location.origin,
    },
  };

  return (
    <Auth0Provider {...providerConfig}>
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot( document.getElementById('root') as HTMLElement );

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
