import { useState, useCallback, ChangeEvent, FormEvent } from 'react';
import FormdataType from '../../../Interface/FormDataType';
import ReportType from '../../../Enums/ReportType';
import { useFetchReport } from '../../../Hooks/useFetchReport';
import { DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';


interface ReportSummaryFormType{
    key: string;
    value: boolean;
}

export type {ReportSummaryFormType};

interface UseReportSummaryFormProps {
    initialData: ReportSummaryFormType[];
    studentPublicId: string;
    setIsSummaryModalVisible: (visible: boolean) => void;
}

interface UseReportSummaryFormReturn {
    formData: ReportSummaryFormType[];
    setFormData: React.Dispatch<React.SetStateAction<ReportSummaryFormType[]>>;
    isEditForm: boolean;
    setIsEditForm: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitting: {fullReport: boolean, goalsReport: boolean, summaryReport: boolean};
    setIsSubmitting: React.Dispatch<React.SetStateAction<{fullReport: boolean, goalsReport: boolean, summaryReport: boolean}>>;
    errors: { [key: string]: string | string[] };
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string | string[] }>>;
    handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleFullReport: (e: FormEvent<HTMLFormElement>) => Promise<void>;
    handleGoalsReport: (e: FormEvent<HTMLFormElement>) => Promise<void>;
    handleSummaryReport: (e: FormEvent<HTMLFormElement>) => Promise<void>;
    asOfDate: string;
    handleDateChange: DatePickerProps['onChange'];
}

export const useReportSummaryForm = ({initialData, studentPublicId, setIsSummaryModalVisible}: UseReportSummaryFormProps): UseReportSummaryFormReturn => {
    const [formData, setFormData] = useState<ReportSummaryFormType[]>(initialData);
    const [isEditForm, setIsEditForm] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState({fullReport: false, goalsReport: false, summaryReport: false})
    const [errors, setErrors] = useState<{ [key: string]: string | string[] }>({});
    const {fetchReport} = useFetchReport();
    const [asOfDate, setAsOfDate] = useState<string>(new Date().toISOString());

    const handleDateChange: DatePickerProps['onChange'] = useCallback((date: Dayjs, dateString: string | string[]) => {
        const dateValue = Array.isArray(dateString) ? dateString[0] : dateString;
        setAsOfDate(dateValue);
    }, []);

    const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setFormData((prevFormData) =>
        prevFormData.map((item) =>
            item.key === id ? { ...item, value: checked } : item
        )
    );
    }, []);

    const handleFullReport = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(isSubmitting.fullReport || isSubmitting.goalsReport || isSubmitting.summaryReport) { return; }
        setIsSubmitting({fullReport: true, goalsReport: false, summaryReport: false});
        const payload: FormdataType = {
            studentPublicId: studentPublicId,
            planTypeId: process.env.REACT_APP_DISABLITY_PLANTYPE_ID || "",
            asOfDate: asOfDate,
            reportType: ReportType.FullReport,
            planningMeeting: true,
            supportPersonnel: true,
            transition: true,
            studentStrength: true,
            evidenceOfAssessedNeeds: true,
            recordOfDisability: true,
            functionalImpact: true,
            historicalNAPLANData: true,
            goals: true,
            professionalDevelopment: true,
            curriculumAssessment: true,
            environmental: true,
            movement: true,
            communication: true,
            personalCare: true,
            safety: true,
            reviewMeeting: true,
            evidenceOfMonitoringReview: true,
            studentAchievedLearningGoals: true,
            socialEmotional: true
        }
        handleSubmit(payload);
    };

    const handleGoalsReport = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(isSubmitting.fullReport || isSubmitting.goalsReport || isSubmitting.summaryReport) { return; }
        setIsSubmitting({fullReport: false, goalsReport: true, summaryReport: false});
        const payload: FormdataType = {
            studentPublicId: studentPublicId,
            planTypeId: process.env.REACT_APP_DISABLITY_PLANTYPE_ID || "",
            asOfDate: asOfDate,
            reportType: ReportType.GoalsReport,
            planningMeeting: false,
            supportPersonnel: false,
            transition: false,
            studentStrength: false,
            evidenceOfAssessedNeeds: false,
            recordOfDisability: false,
            functionalImpact: false,
            historicalNAPLANData: false,
            goals: true,
            professionalDevelopment: false,
            curriculumAssessment: true,
            environmental: true,
            movement: true,
            communication: true,
            personalCare: true,
            safety: true,
            reviewMeeting: false,
            evidenceOfMonitoringReview: false,
            studentAchievedLearningGoals: false,
            socialEmotional: true
        }
        handleSubmit(payload);
    }

    const handleSummaryReport = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(isSubmitting.fullReport || isSubmitting.goalsReport || isSubmitting.summaryReport) { return; }
        setIsSubmitting({fullReport: false, goalsReport: false, summaryReport: true});
        const payload: FormdataType = {
            studentPublicId: studentPublicId,
            planTypeId: process.env.REACT_APP_DISABLITY_PLANTYPE_ID || "",
            asOfDate: asOfDate,
            reportType: ReportType.SummaryReport,
            planningMeeting: formData.find(f => f.key === "planningMeeting")?.value ?? false,
            supportPersonnel: formData.find(f => f.key === "supportPersonnel")?.value ?? false,
            transition: formData.find(f => f.key === "transition")?.value ?? false,
            studentStrength: formData.find(f => f.key === "studentStrength")?.value ?? false,
            evidenceOfAssessedNeeds: formData.find(f => f.key === "evidenceOfAssessedNeeds")?.value ?? false,
            recordOfDisability: formData.find(f => f.key === "recordOfDisability")?.value ?? false,
            functionalImpact: formData.find(f => f.key === "functionalImpact")?.value ?? false,
            historicalNAPLANData: formData.find(f => f.key === "historicalNAPLANData")?.value ?? false,
            goals: formData.find(f => f.key === "goals")?.value ?? false,
            professionalDevelopment: formData.find(f => f.key === "professionalDevelopment")?.value ?? false,
            curriculumAssessment: formData.find(f => f.key === "curriculumAssessment")?.value ?? false,
            environmental: formData.find(f => f.key === "environmental")?.value ?? false,
            movement: formData.find(f => f.key === "movement")?.value ?? false,
            communication: formData.find(f => f.key === "communication")?.value ?? false,
            personalCare: formData.find(f => f.key === "personalCare")?.value ?? false,
            safety: formData.find(f => f.key === "safety")?.value ?? false,
            reviewMeeting: formData.find(f => f.key === "reviewMeeting")?.value ?? false,
            evidenceOfMonitoringReview: formData.find(f => f.key === "evidenceOfMonitoringReview")?.value ?? false,
            studentAchievedLearningGoals: formData.find(f => f.key === "studentAchievedLearningGoals")?.value ?? false,
            socialEmotional: formData.find(f => f.key === "socialEmotional")?.value ?? false
        }
        handleSubmit(payload);
    };
    


    const handleSubmit = async (payload: FormdataType) => {
        try {
            await fetchReport(studentPublicId, payload);
        }catch (err) {
            console.error(err);
        }finally{ 
            setIsSubmitting({fullReport: false, goalsReport: false, summaryReport: false});
            setFormData(initialData);
            setIsSummaryModalVisible(false);
        }
    }


    return {
        formData,
        setFormData,
        isEditForm,
        setIsEditForm,
        isSubmitting,
        setIsSubmitting,
        errors,
        setErrors,
        handleCheckboxChange,
        handleFullReport,
        handleGoalsReport,
        handleSummaryReport,
        asOfDate,
        handleDateChange
    };
};
