import LookupCheckboxItem from "../Types/LookupCheckboxItem";
import React from "react";
import IObjectWithRefId from "../Interface/IObjectWithRefId";
import { LookupCheckboxContext } from "../Context/LookupCheckboxContext";
import { Loading } from "../Components/Layout/Loading";
import { useFetchLookupCheckbox } from "../Hooks/useFetchLookupCheckbox";


export const LookupCheckboxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data, isLoading } = useFetchLookupCheckbox();
    
    if (isLoading) return <Loading />;

    const getLookupCheckboxById = <T extends IObjectWithRefId>(item: T): string => {
      const result = data.find((c: LookupCheckboxItem) => c.publicId.toLowerCase() === item.refId.toLowerCase());
      return result ? (result.hasOther && item.other ? item.other : result.displayValue) : "Not Found";
    };

    const getLookupCheckboxsBySectionId = (sectionId: string): LookupCheckboxItem[] => {
      var checkboxes = new Array<LookupCheckboxItem>();
      checkboxes = data.filter((c: LookupCheckboxItem) => c.sectionPublicId.toLowerCase() === sectionId.toLowerCase());
      checkboxes.sort((a, b) => a.displayOrder - b.displayOrder);
      return checkboxes;
    }
  
  
    return (
      <LookupCheckboxContext.Provider value={{ lookupCheckboxData: data, getLookupCheckboxById, getLookupCheckboxsBySectionId}}>
        {children}
      </LookupCheckboxContext.Provider>
    );
  };