import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faBars, faTimes, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import logoMobile from "../../Assets/Images/logoMobile.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStudentPlan } from "../../Context/StudentPlanContext";
import { Modal } from "antd";
import { ReportSummaryFormType, useReportSummaryForm } from "../Layout/Reports/useReportSummaryForm";
import { ReportSummaryCheckbox, getAllReportSummaryCheckboxes } from "../Layout/Reports/ReportSummaryCheckbox";
import {ReactComponent as FaSpinnerThird} from "../../Assets/Images/FaSpinnerThird.svg";
import { ReportSummaryForm } from "./Reports/ReportSummaryForm";

export const NavBarMobile: React.FC = () => {
    const { logout, user } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isReportsMenuOpen, setIsReportsMenuOpen] = useState(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const {student, studentPublicId} = useStudentPlan();
    const [initialData ] = useState<ReportSummaryFormType[]>(getAllReportSummaryCheckboxes().map((item: ReportSummaryCheckbox) => ({ key: item.key, value: false })));
    const [isSummaryModalVisible, setIsSummaryModalVisible] = useState<boolean>(false);
    const {formData, setFormData, isSubmitting, handleCheckboxChange, handleFullReport, handleGoalsReport, handleSummaryReport, asOfDate, handleDateChange } = useReportSummaryForm({ initialData, studentPublicId, setIsSummaryModalVisible });
    const title = "Summary Report Options";


    const logoutWithRedirect = () =>
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        }
    });

    const toggleMenu = () => {
        if (isReportsMenuOpen) {
            setIsReportsMenuOpen(false);
        } else {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const openReportsMenu = () => setIsReportsMenuOpen(true);

    const openUserMenu = () => setIsUserMenuOpen(true);

    const onHelpClick = () => {
        window.open("https://csnsw.link/disability-support-kb", "_blank");
    };

    const onBackClick = () => {
        setIsReportsMenuOpen(false);
        setIsUserMenuOpen(false);
    }

    const closeMenu = () => {
        setIsMenuOpen(false);
        setIsReportsMenuOpen(false);
        setIsUserMenuOpen(false);
    };

    const onRefreshClick = async () => {
        Modal.confirm({
            title: 'Are you sure you want to clear cache?',
            content: 'Any unsaved changes will be lost and the latest plan details retrieved from the server.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() { localStorage.clear();
                window.location.reload();},
            onCancel() {  },
        }); 
    };

    const handleLogoClick = () => {
        const currentPath = location.pathname;
        const newPath = currentPath.split('/').slice(0, -1).join('/');
        navigate(newPath, { replace: true });
    };


    return (
        <div className="mb-4 bg-white sticky top-0 z-50">
            <div className="flex py-4 pl-4 ">
                <div onClick={handleLogoClick}><img src={logoMobile} className="h-8 sm:h-10 mr-4" alt="csnsw logo"/></div>
                <div className="flex grow justify-between items-center bg-[#005980] rounded-l-lg">
                    <span className="text-sm sm:text-lg font-bold text-[#FFA53A] mx-2 sm:mx-4">CSNSW - Personalised Planning Tool</span>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className="mr-4 text-2xl text-white"/>
                </div>
            </div>

            <div className={`fixed inset-0 bg-white z-50 transition-transform duration-500 ease-in-out ${isMenuOpen ? "translate-y-0" : "-translate-y-full"}`}>

                <div className="flex justify-between items-center p-4">
                    {(isReportsMenuOpen || isUserMenuOpen) && (
                        <FontAwesomeIcon icon={faChevronLeft} onClick={onBackClick} className="text-2xl text-indigo-600 cursor-pointer" />
                    )}
                    <img src={logoMobile} className="h-8 sm:h-10" alt="csnsw logo"/>
                    <FontAwesomeIcon icon={faTimes} onClick={closeMenu} className="text-2xl text-[#c80000]" />
                </div>
                
                <div className=" border-b-2 px-2">
                    <span className="text-xl lg:text-2xl font-bold">{student.studentFirstName} {student.studentLastName} {student.gradeDescription} </span>
                    <span className="flex flex-col lg:mx-4">{student.orgnisationName && ` ${student.orgnisationName} | ${student.locationSuburb}`}</span>
                    <span className="font-bold mx-1"></span>
                </div>

                <div className="p-4 font-bold">
                    {(!isReportsMenuOpen && !isUserMenuOpen) && (
                        <div className="flex flex-col items-start text-2xl">
                            <FontAwesomeIcon icon={faCircleUser} onClick={openUserMenu} className="ml-auto text-3xl rounded-2xl border-2 shadow-circle hover:shadow-blue-400 border-blue-500" />
                            <button className="my-2 cursor-pointer" onClick={openReportsMenu}>Reports</button>
                            <button className="my-2" onClick={onHelpClick}>Help</button>
                            
                        </div>
                    )}
                    {(isReportsMenuOpen && !isUserMenuOpen) && (
                        <div className="text-xl">
                            <form onSubmit={handleFullReport}><button className="my-2 flex">Full Report{isSubmitting.fullReport && <FaSpinnerThird className="m-2 w-4 animate-spin"/>}</button></form>
                            <form onSubmit={handleGoalsReport}><button className="my-4 flex">Goals and Adjustments Report{isSubmitting.goalsReport && <FaSpinnerThird className="mx-2 my-2 w-4 animate-spin"/>}</button></form>
                            <button onClick={()=>setIsSummaryModalVisible(true)} className="my-2">Summary Report</button>
                            <ReportSummaryForm isModalVisible={isSummaryModalVisible} setIsModalVisible={setIsSummaryModalVisible} formData={formData} setFormData={setFormData} handleCheckboxChange={handleCheckboxChange} isSubmitting={isSubmitting} initialData={initialData} title={title} handleSummaryReport={handleSummaryReport} asOfDate={asOfDate} handleDateChange={handleDateChange}/>
                        </div>
                    )}
                    {(!isReportsMenuOpen && isUserMenuOpen) && (
                        <div className="text-xl flex flex-col items-start">
                            <div className="flex flex-col cursor-default my-2">Hi, {user?.name}!</div>
                            <button className="my-2"  onClick={() => logoutWithRedirect()}> Logout </button>
                            <button className="my-2" onClick={onRefreshClick}>Clear Cache</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
