import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { LookupListDisability } from "../Class/NccdDisabilityLegacy";

export const useFetchLookupListDisability = ()=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchFetchLookupListDisability = async () => {
        if(error) { return []; }

        const cachedData = getFromLocalStorage(`LOOKUP_DISABILITIES`);
        if (cachedData) { return cachedData; }
    
        try {
            const endpoint = `nccd/lookup_disabilities`;
            const { data } = await axios.get<LookupListDisability[]>(endpoint);
            saveToLocalStorage(`LOOKUP_DISABILITIES`, data, Number(process.env.REACT_APP_LOOKUP_LIST_EXP_MINUTES));
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            return [];
        }
    };
    
    return useQuery('nccdFetchLookupListDisability', fetchFetchLookupListDisability, {
        staleTime: 0,
        retry: 2,
    });
}