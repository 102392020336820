import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useFormSubmitContext } from "../../Context/FormSubmitContext";

export const FloatingSave = () => {
    const {formData, initialFormData, handleSubmit, isSubmitting} = useFormSubmitContext();
    const [isFormDataChanged, setIsFormDataChanged] = React.useState<boolean>(false);

    useEffect(() => {
        setIsFormDataChanged(JSON.stringify(formData) !== JSON.stringify(initialFormData));
      }, [formData, initialFormData]);
    
  return (
    <div className={`fixed bottom-0 z-50 transition-transform duration-1000 ease-in-out ${isFormDataChanged ? 'translate-y-0' : '-translate-y-full'}`} hidden={!isFormDataChanged}>
        <Button onClick={handleSubmit} disabled={isSubmitting || !isFormDataChanged} className="w-[100vw] bg-green-500 hover:bg-green-600 text-white rounded">
            <FontAwesomeIcon icon={faSave} className="mr-2"/> Save
        </Button>
    </div>
  );
}