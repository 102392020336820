import { useAuthAxios } from "./useAuthAxios";
import { useCallback, useState } from "react";
import { notification } from "antd";

interface FetchReportFunction {
    (studentPublicId: string, payload: any, customMessage?: string): void;
} 

export const useFetchReport = ():{fetchReport: FetchReportFunction}=> {
    const {axios} = useAuthAxios();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const fetchReport = useCallback( async (studentPublicId: string, payload: any, customMessage?: string): Promise<void> => {
        if(isSubmitting){ return; }
        setIsSubmitting(true);

        try {
            const response = await axios.post(`reports/generatestudentreport`, payload, {responseType: 'blob'});

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const disposition = response.headers["content-disposition"];
            let filename = 'StudentReport.pdf';
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
                }
            }
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            notification.success({ message: customMessage ? customMessage : 'Report downloaded successfully!', placement: 'bottomRight' });
            
        } catch (error) {
            notification.error({ message: 'Error downloading report!', placement: 'topRight' });
        }finally{
            setIsSubmitting(false);
        }
    }, [axios, isSubmitting]);

    return {fetchReport};
}