import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Watermark, Grid } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import Aboriginal from '../Pages/Aboriginal/Aboriginal';
import { useAuth0 } from '@auth0/auth0-react';
import { FloatingSave } from '../Components/Layout/FloatingSave';
import { NavBar } from '../Components/Layout/NavBar';
import { NavBarMobile } from '../Components/Layout/NavBarMobile';
import { Loading } from '../Components/Layout/Loading';
import { Footer } from '../Components/Layout/Footer';
import { LookupListProvider } from '../Provider/LookupListProvider';
import { LookupCheckboxProvider } from '../Provider/LookupCheckboxProvider';
import { FormSubmitProvider } from '../Provider/FormSubmitProvider';
import { StudentPlanProvider } from '../Provider/StudentPlanProvider';
import StudentNotFound from '../Pages/Common/StudentNotFound';
import { isValidUUID } from '../Validations/ValidateGuid';
import { ErrorHandlingProvider } from '../Provider/ErrorHandlingProvider';
import InternalServer from '../Pages/Common/InternalServer';
import Unauthorized from '../Pages/Common/Unauthorized';
import { RefsProvider } from '../Provider/RefsProvider';
import { Naplan } from '../Class/Naplan';
import { NaplanProvider } from '../Provider/NaplanProvider';
import { NccdDisabilityProvider } from '../Provider/NccdDisabilityProvider';
const Disability = lazy(() => import('../Pages/Disablity/Disability'));
const NotFound = lazy(() => import('../Pages/Common/NotFound'));

export const enum PlanType{
  DISABILITY = 'DISABILITY',
  ABORIGINAL = 'ABORIGINAL',
}


const App = ()=> {
    const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const queryClient = new QueryClient();
    const navigate = useNavigate();
    const url = window.location.pathname;
    var segments = url.split('/'); 
    const studentPublicId = segments[1].toLowerCase();
    segments = segments.map(segment => segment.toUpperCase());
    const planType:PlanType = segments.find(segment => segment === PlanType.DISABILITY || segment === PlanType.ABORIGINAL) as PlanType || PlanType.DISABILITY;
    

    useEffect(() => {
        const fn = async () => {
          if (!isLoading && !isAuthenticated) {
            const currentPath = window.location.pathname + window.location.search;
            const urlSegments = currentPath.split('/');
            const potentialUUID = urlSegments[1];
            if (isValidUUID(potentialUUID)) {
              localStorage.setItem('urlBeforeLogin', currentPath);
            }
            try {
              await getAccessTokenSilently();
            } catch (e) {
              loginWithRedirect();
            }
          }
        };
        fn();
    }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently]);

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
          const url = localStorage.getItem('urlBeforeLogin');
          if(!url) return;

          const urlSegments = url.split('/');
          const potentialUUID = urlSegments[1];
          if (isValidUUID(potentialUUID)) {
            localStorage.removeItem('urlBeforeLogin');
            navigate(url);
          }
        }
    }, [isAuthenticated, isLoading, navigate]);

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading || !isAuthenticated) {
        return <div><Loading /></div>;
    }


    return (
      <div className='flex flex-col min-h-screen bg-gray-100'>
          <div className='flex flex-col flex-grow'>
            <QueryClientProvider client={queryClient}>
              <ErrorHandlingProvider>
                <LookupListProvider>
                  <LookupCheckboxProvider>
                    <StudentPlanProvider studentPublicId={studentPublicId} planType={planType}>
                      <NccdDisabilityProvider studentPublicId={studentPublicId}>
                        <NaplanProvider studentPublicId={studentPublicId}>
                          <FormSubmitProvider>
                            <RefsProvider>
                              {screens.md ? ( <NavBar /> ) : ( <NavBarMobile /> )}
                                <Suspense fallback={<Loading />}>
                                  <Watermark content={process.env.REACT_APP_WATERMARK} >
                                      <Routes>
                                        <Route path="/:studentPublicId/" element={<Navigate to={`/${studentPublicId}/disability/consultation-and-collaboration`} replace />} />
                                        <Route path="/:studentPublicId/disability" element={<Disability />} />
                                        <Route path="/:studentPublicId/disability/consultation-and-collaboration" element={<Disability />} />
                                        <Route path="/:studentPublicId/disability/assessed-needs" element={<Disability />} />
                                        <Route path="/:studentPublicId/disability/adjustments" element={<Disability />} />
                                        <Route path="/:studentPublicId/disability/monitoring-and-review" element={<Disability />} />
                                        <Route path="/:studentPublicId/aboriginal" element={<Aboriginal />} />
                                        <Route path="/student-not-found" element={<StudentNotFound />}></Route>
                                        <Route path="/unauthorized" element={<Unauthorized />}></Route>
                                        <Route path="/internal-server-error" element={<InternalServer />}></Route>
                                        <Route path="/404" element={<NotFound />}></Route>
                                        <Route path="*" element={<NotFound />}></Route>
                                      </Routes>
                                      {screens.md || screens.sm || <FloatingSave />}
                                </Watermark>
                            </Suspense>
                            <Footer />
                          </RefsProvider>
                        </FormSubmitProvider>
                      </NaplanProvider>
                    </NccdDisabilityProvider>
                  </StudentPlanProvider>
                </LookupCheckboxProvider>
              </LookupListProvider>
            </ErrorHandlingProvider>
          </QueryClientProvider>
        </div>
      </div>
    );
}

export default App;