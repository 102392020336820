import React, { useCallback } from "react";
import { Loading } from "../Components/Layout/Loading";
import { NccdDisabilityContext } from "../Context/NccdDisabilityContext";
import { useFetchNccdDisability } from "../Hooks/useFetchNccdDisability";
import { useFetchLookupListDisability } from "../Hooks/useFetchLookupListDisabilities";
import { LookupListDisability, LookupListDisabilityPriority, LookupListDisabilityType, NccdDisabilityLegacy } from "../Class/NccdDisabilityLegacy";
import { useFetchLookupDisabilityCategory } from "../Hooks/useFetchLookupDisabilityCategory";
import { Disability, NccdDisability } from "../Class/StudentPlan";
import { v4 as uuidv4 } from 'uuid';
import LookupValue from "../Enums/LookupValue";

interface NccdDisabilityProviderProps {
    studentPublicId: string;
    children: React.ReactNode;
}

export const NccdDisabilityProvider: React.FC<NccdDisabilityProviderProps> = ({studentPublicId, children }) => {
    const [nccdDisabilityDataLegacy, setNccdDisabilityDataLegacy] = React.useState<NccdDisabilityLegacy[]>([]);
    const { data: lookupDisabilityData, isLoading: isLoadingLookup } = useFetchLookupListDisability();
    const { data: lookupDisabilityCategory, isLoading: isLoadingCategory } = useFetchLookupDisabilityCategory();
    const { isLoading } = useFetchNccdDisability(studentPublicId, setNccdDisabilityDataLegacy);


    if (isLoading || isLoadingCategory || isLoadingLookup) return <Loading />;


    const lookupDisabilityPriority: LookupListDisabilityPriority[] = [
        {priorityOrder: 1, priorityName: "Primary"},
        {priorityOrder: 2, priorityName: "Additional"}
    ];

    const lookupDisabilityType: LookupListDisabilityType[] = [
        {typeId: 1, typeName: "Diagnosed"},
        {typeId: 2, typeName: "Imputed"}
    ];



    const getNccdDisabilityType = (item:number): string => {
        switch(item){
            case 39:
                return LookupValue.NccdDisabilityImputed;
            case 40:
                return LookupValue.NccdDisabilityImputed;
            case 41:
                return LookupValue.NccdDisabilityImputed;
            case 42:
                return LookupValue.NccdDisabilityImputed;
            default:
                return LookupValue.NccdDisabilityDiagnosed;
        }
    }


    const getNccdDisabilityPriority = (item:number): string => {
        if (item === 1) {
            return LookupValue.NccdDisabilityPrimary;
        }else {
            return LookupValue.NccdDisabilityAdditional;
        }
    }

    const getNccdDisabilityPriorityFromGuid = (item: NccdDisability): number => {
        if (item.priority === LookupValue.NccdDisabilityPrimary) {
            return 1;
        }
            return 2;
    }

    const getNccdDisabilityCategory = (item:number): string => {
        const dis = lookupDisabilityData.find((d: NccdDisabilityLegacy)=> d.nccddisabilityId === item);

        switch(dis?.categoryId){
            case 2:
                return LookupValue.NccdDisabilityCategoryCognitive;
            case 3:
                return LookupValue.NccdDisabilityCategorySensory;
            case 4:
                return LookupValue.NccdDisabilityCategorySocial;
            default:
                return LookupValue.NccdDisabilityCategoryPhysical;;
        }
    }

    const getNccdDisabilitySpecification = (item:number): string => {

        switch(item){
            case 1:
                return '12786383-831f-4787-8b5f-3583710b13a3';
            case 2:
                return '5214a4b3-1f75-44d6-b3e7-1f12f8a1ff92';
            case 3:
                return '754f7e6d-b5c0-4d02-af1f-bbcb6d39034e';
            case 4:
                return 'aa053058-55aa-4f15-8574-c36b7932f46a';
            case 5:
                return '443ca484-c2d4-49e1-ac50-24bf9c7d7daa';
            case 6:
                return '233cdede-d113-418b-bf62-45713dbe7347';
            case 7:
                return '15f7eac3-c899-4896-8d70-05df27544d28';
            case 8:
                return '55a9b0b3-65af-48d2-a0f3-66f8751f5793';
            case 9:
                return 'fda55e8b-0726-49a0-8154-ed1a1d32f850';
            case 10:
                return '29cfbd0a-ba8c-449c-9b02-3aeda5cbb940';
            case 11:
                return '66bc4eb1-7a65-4c94-8ca1-f647fef0d408';
            case 12:
                return '034f52fd-b4c8-4d5d-a1c5-616410775ef6';
            case 13:
                return '16e854dd-ccf9-416d-80f1-e758342cd46e';
            case 15:
                return '5332a587-ac8c-4c8c-bceb-6b40c40d71e5';
            case 16:
                return '852cd0e4-4383-4777-b9a4-29a602e244ad';
            case 17:
                return '96bd12cf-8580-42c3-aae9-d67396549085';
            case 18:
                return '1fd80d80-7b0f-4cb9-8929-763a8d0c8a57';
            case 19:
                return '6dbe5293-3393-4a0c-87b4-43d2b3b1ca78';
            case 20:
                return '98a645d1-9706-4e08-8eeb-e48690fdc8cd';
            case 21:
                return 'f1eba2cc-0a29-46b0-9600-f4ad31141871';
            case 22:
                return 'f5ea4e8c-c0a4-4a83-95b0-fadc64d7c0eb';
            case 23:
                return '050eefc4-d018-4791-bb5b-29205b8c52bf';
            case 24:
                return '5801354e-f2e0-4d45-8a12-fd432dbac8fb';
            case 25:
                return '9f117ae6-3b34-4e29-9da7-a1180b511dc5';
            case 27:
                return '20e6b75c-8cc3-4a4d-b1d8-249d95bb6d94';
            case 28:
                return '1305eff9-f647-46f1-a603-3fcbc4591c13';
            case 29:
                return '9a616255-4edf-4e11-a8be-67b6c4ad654e';
            case 30:
                return '6234d44a-8bba-4876-b589-474cbfc6ee51';
            case 31:
                return 'fe64c3a1-e473-4ea2-b431-22b22105b488';
            case 32:
                return 'a217b76e-ca0f-4e07-9c91-27904503e008';
            case 33:
                return '2cca1d58-dd11-4ff7-b3b0-a02e6f6926ca';
            case 34:
                return '26027945-f61d-4e91-b354-dd18dd296717';
            case 35:
                return 'cd25efcb-4a95-49c4-a1bd-5ccc6d8d835e';
            case 36:
                return 'a39ad01d-c7a2-4d2b-8e66-da4a2fe8ad77';
            case 37:
                return 'e07fd09c-8f39-441f-ac2f-f2f6793251cb';
            case 38:
                return '33b0034b-bab1-45ff-8c99-04b329d4c143';
            case 39:
                return 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072';
            case 40:
                return 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072';
            case 41:
                return 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072';
            case 42:
                return 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072';
            case 43:
                return '4ed87a99-4d31-480d-a4b7-1ad6ad11fb7d';
            case 45:
                return 'f630f2a5-c4bd-4a93-a685-0bbbd838cfe4';
            case 46:
                return 'a03673d6-0d1c-48a5-a77b-26ac979a9972';
            default:
                return 'disability specific not found';
        }
    }

    const getNccdDisabilitySpecificationFromGuid = (item: NccdDisability): number => {
        var result = 0;

        switch(item.category.specific.refId){
            case '12786383-831f-4787-8b5f-3583710b13a3':
                result = 1;
                break;
            case '5214a4b3-1f75-44d6-b3e7-1f12f8a1ff92':
                result = 2;
                break;
            case '754f7e6d-b5c0-4d02-af1f-bbcb6d39034e':
                result = 3;
                break;
            case 'aa053058-55aa-4f15-8574-c36b7932f46a':
                result = 4;
                break;
            case '443ca484-c2d4-49e1-ac50-24bf9c7d7daa':
                result = 5;
                break;
            case '233cdede-d113-418b-bf62-45713dbe7347':
                result = 6;
                break;
            case '15f7eac3-c899-4896-8d70-05df27544d28':
                result = 7;
                break;
            case '55a9b0b3-65af-48d2-a0f3-66f8751f5793':
                result = 8;
                break;
            case 'fda55e8b-0726-49a0-8154-ed1a1d32f850':
                result = 9;
                break;
            case '29cfbd0a-ba8c-449c-9b02-3aeda5cbb940':
                result = 10;
                break;
            case '66bc4eb1-7a65-4c94-8ca1-f647fef0d408':
                result = 11;
                break;
            case '034f52fd-b4c8-4d5d-a1c5-616410775ef6':
                result = 12;
                break;
            case '16e854dd-ccf9-416d-80f1-e758342cd46e':
                result = 13;
                break;
            case '5332a587-ac8c-4c8c-bceb-6b40c40d71e5':
                result = 15;
                break;
            case '852cd0e4-4383-4777-b9a4-29a602e244ad':
                result = 16;
                break;
            case '96bd12cf-8580-42c3-aae9-d67396549085':
                result = 17;
                break;
            case '1fd80d80-7b0f-4cb9-8929-763a8d0c8a57':
                result = 18;
                break;
            case '6dbe5293-3393-4a0c-87b4-43d2b3b1ca78':
                result = 19;
                break;
            case '98a645d1-9706-4e08-8eeb-e48690fdc8cd':
                result = 20;
                break;
            case 'f1eba2cc-0a29-46b0-9600-f4ad31141871':
                result = 21;
                break;
            case 'f5ea4e8c-c0a4-4a83-95b0-fadc64d7c0eb':
                result = 22;
                break;
            case '050eefc4-d018-4791-bb5b-29205b8c52bf':
                result = 23;
                break;
            case '5801354e-f2e0-4d45-8a12-fd432dbac8fb':
                result = 24;
                break;
            case '9f117ae6-3b34-4e29-9da7-a1180b511dc5':
                result = 25;
                break;
            case '20e6b75c-8cc3-4a4d-b1d8-249d95bb6d94':
                result = 27;
                break;
            case '1305eff9-f647-46f1-a603-3fcbc4591c13':
                result = 28;
                break;
            case '9a616255-4edf-4e11-a8be-67b6c4ad654e':
                result = 29;
                break;
            case '6234d44a-8bba-4876-b589-474cbfc6ee51':
                result = 30;
                break;
            case 'fe64c3a1-e473-4ea2-b431-22b22105b488':
                result = 31;
                break;
            case 'a217b76e-ca0f-4e07-9c91-27904503e008':
                result = 32;
                break;
            case '2cca1d58-dd11-4ff7-b3b0-a02e6f6926ca':
                result = 33;
                break;
            case '26027945-f61d-4e91-b354-dd18dd296717':
                result = 34;
                break;
            case 'cd25efcb-4a95-49c4-a1bd-5ccc6d8d835e':
                result = 35;
                break;
            case 'a39ad01d-c7a2-4d2b-8e66-da4a2fe8ad77':
                result = 36;
                break;
            case 'e07fd09c-8f39-441f-ac2f-f2f6793251cb':
                result = 37;
                break;
            case '33b0034b-bab1-45ff-8c99-04b329d4c143':
                result = 38;
                break;
            case 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072':
                result = 39 ;
                break;
            case '4ed87a99-4d31-480d-a4b7-1ad6ad11fb7d':
                result = 43;
                break;
            case 'f630f2a5-c4bd-4a93-a685-0bbbd838cfe4':
                result = 45;
                break;
            case 'a03673d6-0d1c-48a5-a77b-26ac979a9972':
                result = 46;
                break;
            default:
                result = 0;
        }

        if(result === 39 && item.category.refId === LookupValue.NccdDisabilityCategoryCognitive){
            result = 40;
        }

        if(result === 39 && item.category.refId === LookupValue.NccdDisabilityCategorySensory){
            result = 41;
        }

        if(result === 39 && item.category.refId === LookupValue.NccdDisabilityCategorySocial){
            result = 42;
        }
        return result;
    }

    const getNccdDisabilitySpecificationOther = (item:NccdDisabilityLegacy): string => {
        const dis = lookupDisabilityData.find((d: NccdDisabilityLegacy)=> d.nccddisabilityId === item.nccddisabilityId);

        switch(item.nccddisabilityId){
            case 39:
                return item.imputedDescription;
            case 40:
                return item.imputedDescription;
            case 41:
                return item.imputedDescription;
            case 42:
                return item.imputedDescription;
            case 10:
                return item.otherDisabilityName;
            case 19:
                return item.otherDisabilityName;
            case 25:
                return item.otherDisabilityName;
            case 38:
                return item.otherDisabilityName;
            default:
                return dis?.disabilityName;
        }
    }

    const convertFromLegacyDisability = (item: NccdDisabilityLegacy): NccdDisability => {
        return {
            id: item.studentNccddisabilityId.toString(),
            refId: getNccdDisabilityType(item.nccddisabilityId),
            other: '',
            priority: item.disabilityOrder ?  LookupValue.NccdDisabilityPrimary : LookupValue.NccdDisabilityAdditional,
            category: {
                id: uuidv4(),
                refId: getNccdDisabilityCategory(item.nccddisabilityId),
                other: '',
                specific: {
                    id: uuidv4(),
                    refId: getNccdDisabilitySpecification(item.nccddisabilityId),
                    other: getNccdDisabilitySpecificationOther(item)
                }
            }
        }
    }

    const convertToLegacyDisability = (item: NccdDisability, studentId: number): NccdDisabilityLegacy => {
        return {
            studentNccddisabilityId: parseInt(item.id),
            nccddisabilityId: getNccdDisabilitySpecificationFromGuid(item),
            disabilityOrder: item.priority === LookupValue.NccdDisabilityPrimary,
            otherDisabilityName: item.refId === LookupValue.NccdDisabilityDiagnosed ? item.category.specific.other : '',
            studentId: studentId,
            studentPublicId: studentPublicId,
            imputedDescription: item.refId === LookupValue.NccdDisabilityImputed ? item.category.specific.other : '',
        }
    }
    
    if (isLoading || isLoadingLookup || isLoadingCategory) return <Loading />;

    return (
        <NccdDisabilityContext.Provider value={{nccdDisabilityDataLegacy, getNccdDisabilityType, getNccdDisabilityPriority, getNccdDisabilityCategory, getNccdDisabilitySpecification, getNccdDisabilitySpecificationOther, convertToLegacyDisability, convertFromLegacyDisability, setNccdDisabilityDataLegacy, lookupDisabilityData: lookupDisabilityData, lookupDisabilityCategory, lookupDisabilityType, lookupDisabilityPriority}}>
            {children}
        </NccdDisabilityContext.Provider>
    );
};