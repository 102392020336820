interface ReportSummaryCheckbox{
    key: string;
    value: string;
}
export type { ReportSummaryCheckbox };

export const ReportSummaryConsultationCheckbox = ():ReportSummaryCheckbox[] =>{
    return [
        {key: "planningMeeting", value: "Planning Meeting Details"},
        {key: "supportPersonnel", value: "Details of Support Personnel"},
        {key: "transition", value: "Transition"},
    ]
}

export const ReportSummaryAssessedCheckbox = ():ReportSummaryCheckbox[] =>{
    return [
        {key: "studentStrength", value: "Student Strengths"},
        {key: "evidenceOfAssessedNeeds", value: "Evidence Of Assessed Needs"},
        {key: "recordOfDisability", value: "Record Of Disability"},
        {key: "functionalImpact", value: "Functional Impact"},
        {key: "historicalNAPLANData", value: "Historical NAPLAN Data"},
    ]
}

export const ReportSummaryAdjustmentCheckbox = ():ReportSummaryCheckbox[] =>{
    return [
        {key: "goals", value: "Goals"},
        {key: "professionalDevelopment", value: "Professional Development"},
        {key: "curriculumAssessment", value: "Curriculum and Assessment"},
        {key: "environmental", value: "Communication"},
        {key: "movement", value: "Environmental"},
        {key: "communication", value: "Movement"},
        {key: "personalCare", value: "Personal Care"},
        {key: "safety", value: "Safety"},
        {key: "socialEmotional", value: "Social & Emotional"},
    ]
}

export const ReportSummaryMonitoringCheckbox = ():ReportSummaryCheckbox[] =>{
    return [
        {key: "reviewMeeting", value: "Monitoring & Review Meeting Details"},
        {key: "evidenceOfMonitoringReview", value: "Evidence Of Monitoring & Review"},
        {key: "studentAchievedLearningGoals", value: "Student Achieved Learning Goals"},
    ]
}

export const getAllReportSummaryCheckboxes = (): ReportSummaryCheckbox[] => {
    return [
        ...ReportSummaryConsultationCheckbox(),
        ...ReportSummaryAssessedCheckbox(),
        ...ReportSummaryAdjustmentCheckbox(),
        ...ReportSummaryMonitoringCheckbox(),
    ];
};