import { useQuery } from "react-query";
import { getFromLocalStorage, saveToLocalStorage } from "../Util/localStorageUtil";
import { Aboriginal, Disability, StudentPlanData } from "../Class/StudentPlan";
import { useEffect } from "react";
import { Student } from "../Class/Student";
import { useAuthAxios } from "./useAuthAxios";
import { notification } from "antd";
import { PlanType } from "../Container/App";
import { isValidUUID } from "../Validations/ValidateGuid";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import React from "react";


export const useFetchStudentPlans = (planType: PlanType, studentPublicId: string, setStudentPlanData: (disabilityData?: StudentPlanData['disability'], aboriginalData?: StudentPlanData['aboriginal'])=>void, setStudent: React.Dispatch<React.SetStateAction<Student>>) => {
  const {axios} = useAuthAxios();
  const {error, setError } = useErrorHandling();
  

  const fetchStudent = async (): Promise<Student | null> => {
    if(error) { return null; }

    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return null;
    }

    const cachedStudentData = getFromLocalStorage(`STUDENT_${studentPublicId.toLowerCase()}`);
    if (cachedStudentData) return cachedStudentData;

    const endpoint = `plans/header?studentPublicId=${studentPublicId}`;
    try{
      const { data: studentData } = await axios.get<Student>(endpoint);
      saveToLocalStorage(`STUDENT_${studentPublicId.toLowerCase()}`, studentData, Number(process.env.REACT_APP_DATA_EXP_MINUTES));
      setError(null);
      return studentData;
    }catch(error){
      setError(error);
      notification.error({ message: 'Error fetching student data!', placement: 'topRight' });
      return null;
    }
  }

  const fetchDisabilityPlan = async (): Promise<Disability | null> => {
    if(error) { return null; }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return null;
    }

    const cachedPlanData = await getFromLocalStorage(`${PlanType.DISABILITY}_${studentPublicId.toLocaleLowerCase()}`);
    if (cachedPlanData) { return (cachedPlanData)}
    
    if(planType !== PlanType.DISABILITY) return null;

    if(error) { return null; }

    const endpoint = `plans/${studentPublicId}/${process.env.REACT_APP_DISABLITY_PLANTYPE_ID}`;
    try{
      const { data: disabilityData } = await axios.get<Disability>(endpoint); 
      setError(null);
      return disabilityData;
    }
    catch(error){
      setError(error);
      notification.error({ message: 'Error fetching disability plan data!', placement: 'topRight' });
      return null;
    }
  };

  const fetchAboriginalPlan = async (): Promise<Aboriginal | null> => {
    if(error) { return null; }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return null;
    }
    
    const cachedPlanData = getFromLocalStorage(`${PlanType.ABORIGINAL}_${studentPublicId.toLowerCase()}`);
    if (cachedPlanData) return cachedPlanData;

    if(planType !== PlanType.ABORIGINAL) return null;

    

    const endpoint = `plans/${studentPublicId}/${process.env.REACT_APP_ABORIGINAL_PLANTYPE_ID}`;
    try{
      const { data: aboriginalData } = await axios.get<Aboriginal>(endpoint);
      setError(null);
      return aboriginalData;
    }catch(error){
      setError(error);
      notification.error({ message: 'Error fetching aboriginal plan data!', placement: 'topRight' });
      return null;
    }
  };




  const studentQuery = useQuery<Student | null>(['fetchStudent', studentPublicId], fetchStudent, {
    staleTime: 0,
    retry: 2,
  });

  const disabilityQuery = useQuery<Disability | null>(['fetchDisabilityPlan', studentPublicId], fetchDisabilityPlan, {
    staleTime: 0,
    retry: 2,
  });

  const aboriginalQuery = useQuery<Aboriginal | null>(['fetchAboriginalPlan', studentPublicId], fetchAboriginalPlan, {
    staleTime: 0,
    retry: 2,
  });



  useEffect(() => {
    if (!disabilityQuery.isLoading && !aboriginalQuery.isLoading && !studentQuery.isLoading) {
      if(studentQuery.data){
        setStudent(studentQuery.data);
      }
      if(disabilityQuery.data){
        setStudentPlanData( disabilityQuery.data );
      }
      if(aboriginalQuery.data){
        setStudentPlanData( undefined, aboriginalQuery.data );
      }
    }
  }, [ disabilityQuery.data, aboriginalQuery.data, setStudentPlanData, disabilityQuery.isLoading, aboriginalQuery.isLoading, studentQuery.isLoading, setStudent, studentQuery.data]);

  
//   useEffect(() => {
//     connection.on("PlanUpdated", (updatedPlanId: string, modifiedBy: string) => {
//       if (studentPlanPublicId === updatedPlanId) {
//         onUpdate();
//         console.log(`Plan updated by ${modifiedBy}`);
//         localStorage.removeItem(`${PlanType.DISABILITY}_${studentPublicId.toLocaleLowerCase()}`);
//         disabilityQuery.refetch();
//       }
//   });

//   return () => {
//       connection.off("PlanUpdated");
//   };
// }, [studentPlanPublicId]);

  return {
    isDisabilityLoading: disabilityQuery.isLoading,
    isAboriginalLoading: aboriginalQuery.isLoading,
    isStudentLoading: studentQuery.isLoading
  };
};