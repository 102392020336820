import {  Assessment, Identifiable, Disability, Intensity, MeetingDetail, StudentStrength, SupportPersonnel, Transition, FunctionalImpactSection, NccdDisability, Category, Goal, ProfessionalDevelopment, CurriculumAssessmentSection, DeliveryAdjustment, AssessmentAdjustmentsSection, CommunicationSection, EnvironmentalSection, MovementSection, PersonalCareSection, SafetySection, SocialEmotionalSection, EvidenceMonitoringSection, ExternalAssessmentSection, InternalAssessmentSection } from "../Class/StudentPlan";

function isIdentifiable(obj: any): obj is Identifiable {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}



function isMeetingDetail(obj: any): obj is MeetingDetail {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['meetingDate', typeof obj.meetingDate === 'string'],
        ['purposes', Array.isArray(obj.purposes) && obj.purposes.every(isIdentifiable)],
        ['summary', typeof obj.summary === 'string'],
        ['roles', Array.isArray(obj.roles) && obj.roles.every(isIdentifiable)],
        ['isArchived', typeof obj.isArchived === 'boolean']
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}



function isIntensity(obj: any): obj is Intensity {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['frequency', isIdentifiable(obj.frequency)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isSupportPersonnel(obj: any): obj is SupportPersonnel {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['name', typeof obj.name === 'string'],
        ['year', typeof obj.year === 'string'],
        ['timeFrame', typeof obj.timeFrame === 'string'],
        ['intensities', Array.isArray(obj.intensities) && obj.intensities.every(isIntensity)],
        ['isArchived', typeof obj.isArchived === 'boolean']
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isTransition(obj: any): obj is Transition {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['transitionDate', typeof obj.transitionDate === 'string'],
        ['keyPersonnels', Array.isArray(obj.keyPersonnels) && obj.keyPersonnels.every(isIdentifiable)],
        ['isArchived', typeof obj.isArchived === 'boolean']
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isStudentStrength(obj: any): obj is StudentStrength {
    if (!obj) {
        return false;
    }

    const checks = [
        ['studentInterests', Array.isArray(obj.studentInterests) && obj.studentInterests.every(isIdentifiable)],
        ['areaOfPersonalStrength', typeof obj.areaOfPersonalStrength === 'string' || obj.other === undefined]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isExternalAssessmentSection(obj: any): obj is ExternalAssessmentSection {
    const result = obj && typeof obj === 'object' &&
        Array.isArray(obj.externalAssessments) && obj.externalAssessments.every(isAssessment) &&
        (typeof obj.diagnosticInformation === 'string' || obj.diagnosticInformation === undefined);
    return result;
}

function isInternalAssessmentSection(obj: any): obj is InternalAssessmentSection {
    const result = obj && typeof obj === 'object' &&
        Array.isArray(obj.internalAssessments) && obj.internalAssessments.every(isAssessment) &&
        (typeof obj.imputedDisabilityInformation === 'string' || obj.imputedDisabilityInformation === undefined);
    return result;
}

function isAssessment(obj: any): obj is Assessment {
    return (
        obj &&
        typeof obj.id === 'string' &&
        typeof obj.refId === 'string' &&
        (typeof obj.other === 'string' || obj.other === undefined) &&
        typeof obj.assessmentDate === 'string' &&
        typeof obj.isCurrent === 'boolean' &&
        typeof obj.details === 'string' &&
        typeof obj.fileLocation === 'string' &&
        typeof obj.isArchived === 'boolean'
    );
}

function isFunctionalImpactSection(obj: any): obj is FunctionalImpactSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['functionalImpacts', Array.isArray(obj.functionalImpacts) && obj.functionalImpacts.every(isIdentifiable)],
        ['currentAdditionalPlans', Array.isArray(obj.currentAdditionalPlans) && obj.currentAdditionalPlans.every(isIdentifiable)],
        ['schoolAttendanceInformation', typeof obj.schoolAttendanceInformation === 'string' || obj.other === undefined]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isCategory(obj: any): obj is Category {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['specific', isIdentifiable(obj.specific)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isNccdDisability(obj: any): obj is NccdDisability {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['priority', typeof obj.priority === 'string'],
        ['category', isCategory(obj.category)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isGoal(obj: any): obj is Goal {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['studentLearningGoal', typeof obj.studentLearningGoal === 'string'],
        ['implementationDate', typeof obj.implementationDate === 'string'],
        ['isAchieved', typeof obj.isAchieved === 'boolean'],
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}


function isProfessionalDevelopment(obj: any): obj is ProfessionalDevelopment {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['typeOfTraining', typeof obj.typeOfTraining === 'string'],
        ['serviceProvider', typeof obj.serviceProvider === 'string'],
        ['attendees', Array.isArray(obj.attendees) && obj.attendees.every(isIdentifiable)],
        ['frequency', typeof obj.frequency === 'string'],
        ['meetindDate', typeof obj.meetingDate === 'string'],
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isDeliveryAdjustment(obj: any): obj is DeliveryAdjustment {
    if (!obj) {
        return false;
    }

    const checks = [
        ['id', typeof obj.id === 'string'],
        ['refId', typeof obj.refId === 'string'],
        ['other', typeof obj.other === 'string' || obj.other === undefined],
        ['learningEnvironmentSettings', Array.isArray(obj.learningEnvironmentSettings) && obj.learningEnvironmentSettings.every(isIdentifiable)],
        ['frequency', isIdentifiable(obj.frequency)],
        ['timeFrame', typeof obj.timeFrame === 'string'],
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isAssessmentAdjustmentSection(obj: any): obj is AssessmentAdjustmentsSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['assessmentAdjustments', Array.isArray(obj.assessmentAdjustments) && obj.assessmentAdjustments.every(isIdentifiable)],
        ['specificAdjustmentsIntervention', typeof obj.specificAdjustmentsIntervention === 'string' || obj.specificAdjustmentsIntervention === undefined],
        ['specificAdjustmentsDisability', typeof obj.specificAdjustmentsDisability === 'string' || obj.specificAdjustmentsDisability === undefined],
        ['specialistSoftware', typeof obj.specialistSoftware === 'string' || obj.specialistSoftware === undefined],
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;

}

function isCurriculumAssessmentSection(obj: any): obj is CurriculumAssessmentSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['syllabusOutcomes', Array.isArray(obj.syllabusOutcomes) && obj.syllabusOutcomes.every(isIdentifiable)],
        ['syllabusFurtherDetails', typeof obj.syllabusFurtherDetails === 'string' || obj.syllabusFurtherDetails === undefined],
        ['interventions', Array.isArray(obj.interventions) && obj.interventions.every(isIdentifiable)],
        ['alternateMaterials', Array.isArray(obj.alternateMaterials) && obj.alternateMaterials.every(isIdentifiable)],
        ['tasks', Array.isArray(obj.tasks) && obj.tasks.every(isIdentifiable)],
        ['explicitTeachings', Array.isArray(obj.explicitTeachings) && obj.explicitTeachings.every(isIdentifiable)],
        ['visualSupports', Array.isArray(obj.visualSupports) && obj.visualSupports.every(isIdentifiable)],
        ['assistiveTechnologies', Array.isArray(obj.assistiveTechnologies) && obj.assistiveTechnologies.every(isIdentifiable)],
        ['brailleLowVisions', Array.isArray(obj.brailleLowVisions) && obj.brailleLowVisions.every(isIdentifiable)],
        ['assessmentAdjustmentsSection', isAssessmentAdjustmentSection(obj.assessmentAdjustmentsSections)],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isCommunicationSection(obj: any): obj is CommunicationSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['communications', Array.isArray(obj.communications) && obj.communications.every(isIdentifiable)],
        ['furtherInformationCommunication', typeof obj.furtherInformationCommunication === 'string' || obj.furtherInformationCommunication === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isEnvironmentalSections (obj: any): obj is EnvironmentalSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['environmentals', Array.isArray(obj.environmentals) && obj.environmentals.every(isIdentifiable)],
        ['furtherInformationEnvironmental', typeof obj.furtherInformationEnvironmental === 'string' || obj.furtherInformationEnvironmental === undefined],
        ['furtherInformationSpecialistEquipment', typeof obj.furtherInformationSpecialistEquipment === 'string' || obj.furtherInformationSpecialistEquipment === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isMovementSection(obj: any): obj is MovementSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['movements', Array.isArray(obj.movements) && obj.movements.every(isIdentifiable)],
        ['movementPlannings', Array.isArray(obj.movementPlannings) && obj.movementPlannings.every(isIdentifiable)],
        ['furtherInformationMovement', typeof obj.furtherInformationMovement === 'string' || obj.furtherInformationMovement === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isPersonalCareSection(obj: any): obj is PersonalCareSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['personalCares', Array.isArray(obj.personalCares) && obj.personalCares.every(isIdentifiable)],
        ['personalCarePlannings', Array.isArray(obj.personalCarePlannings) && obj.personalCarePlannings.every(isIdentifiable)],
        ['furtherInformationPersonalCare', typeof obj.furtherInformationPersonalCare === 'string' || obj.furtherInformationPersonalCare === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}

function isSafetySection(obj: any): obj is SafetySection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['safeties', Array.isArray(obj.safeties) && obj.safeties.every(isIdentifiable)],
        ['safetyPlannings', Array.isArray(obj.safetyPlannings) && obj.safetyPlannings.every(isIdentifiable)],
        ['furtherInformationSafety', typeof obj.furtherInformationSafety === 'string' || obj.furtherInformationSafety === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;

}

function isSocialEmotionalSection(obj: any): obj is SocialEmotionalSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['socialEmotionals', Array.isArray(obj.socialEmotionals) && obj.socialEmotionals.every(isIdentifiable)],
        ['socialEmotionalPlannings', Array.isArray(obj.socialEmotionalPlannings) && obj.socialEmotionalPlannings.every(isIdentifiable)],
        ['furtherInformationSocialEmotional', typeof obj.furtherInformationSocialEmotional === 'string' || obj.furtherInformationSocialEmotional === undefined],
        ['deliveryAdjustments', Array.isArray(obj.deliveryAdjustments) && obj.deliveryAdjustments.every(isDeliveryAdjustment)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;

}

function isEvidenceMonitoringSection(obj: any): obj is EvidenceMonitoringSection {
    if (!obj) {
        return false;
    }

    const checks = [
        ['evidenceMonitorings', Array.isArray(obj.evidenceMonitorings) && obj.evidenceMonitorings.every(isIdentifiable)],
        ['furtherDetailsEvidenceMonitoring', typeof obj.furtherDetailsEvidenceMonitoring === 'string' || obj.furtherDetailsEvidenceMonitoring === undefined],
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;

}



function isDisability(obj: any): obj is Disability {
    if (!obj) {
        return false;
    }
    const checks = [
        ['meetingDetails', Array.isArray(obj.meetingDetails) && obj.meetingDetails.every(isMeetingDetail)],
        ['supportPersonnels', Array.isArray(obj.supportPersonnels) && obj.supportPersonnels.every(isSupportPersonnel)],
        ['transitions', Array.isArray(obj.transitions) && obj.transitions.every(isTransition)],
        ['studentStrengths', isStudentStrength(obj.studentStrengths)],
        ['externalAssessmentSections', isExternalAssessmentSection(obj.externalAssessmentSections)],
        ['internalAssessmentSections', isInternalAssessmentSection(obj.internalAssessmentSections)],
        ['functionalImpactSections', isFunctionalImpactSection(obj.functionalImpactSections)],
        ['nccdDisabilities', Array.isArray(obj.nccdDisabilities) && obj.nccdDisabilities.every(isNccdDisability)],
        ['goals', Array.isArray(obj.goals) && obj.goals.every(isGoal)] &&
        ['professionalDevelopments', Array.isArray(obj.professionalDevelopments) && obj.professionalDevelopments.every(isProfessionalDevelopment)] &&
        ['curriculumAssessmentSections', isCurriculumAssessmentSection(obj.curriculumAssessmentSections)] &&
        ['communicationSections', isCommunicationSection(obj.communicationSections)] &&
        ['environmentalSections', isEnvironmentalSections(obj.environmentalSections)] &&
        ['movementSections', isMovementSection(obj.movementSections)] &&
        ['personalCareSections', isPersonalCareSection(obj.personalCareSections)] &&
        ['safetySections', isSafetySection(obj.safetySections)] &&
        ['socialEmotionalSections', isSocialEmotionalSection(obj.socialEmotionalSections)] &&
        ['evidenceMonitoringSections', isEvidenceMonitoringSection(obj.evidenceMonitoringSections)]
    ];

    for (const [passed] of checks) {
        if (!passed) {
            return false;
        }
    }

    return true;
}



export default isDisability;
