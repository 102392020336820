import LookupCheckboxItem from "../Types/LookupCheckboxItem"

function isLookupCheckboxItem(obj: any): obj is LookupCheckboxItem {
    return (
        obj &&
        typeof obj.publicId === 'string' &&
        typeof obj.sectionPublicId === 'string' &&
        typeof obj.displayOrder === 'number' &&
        typeof obj.displayValue === 'string' &&
        typeof obj.hasOther === 'boolean' &&
        (typeof obj.helpText === 'string' || obj.helpText === null) &&
        typeof obj.archived === 'boolean' &&
        typeof obj.linkData === 'number'
    );
}

function isLookupCheckboxItems(items: any[]): items is LookupCheckboxItem[] {
    if(!Array.isArray(items)) return false;
    return items.every(isLookupCheckboxItem);
}

export default isLookupCheckboxItems;