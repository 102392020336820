import React, { createContext, useContext } from 'react';
import { Disability } from '../Class/StudentPlan';

type FormSubmitContextType = {
    formData: Disability;
    setFormData: React.Dispatch<React.SetStateAction<Disability>>;
    initialFormData: Disability;
    handleSubmit: () => void;
    isSubmitting: boolean;
    setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    errors: { [key: string]: string | string[] };
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string | string[] }>>;
    setHasInitialized: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FormSubmitContext = createContext<FormSubmitContextType>({
    initialFormData: new Disability(),
    formData: new Disability(),
    setFormData: () => {},
    handleSubmit: () => {},
    isSubmitting: false,
    setIsSubmitting: () => {},
    errors: {},
    setErrors: () => {},
    setHasInitialized: () => {}
});

export const useFormSubmitContext = () => useContext(FormSubmitContext);
